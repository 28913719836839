<template>
<div class="card">
          <div class="text-2xl capitalize">{{recipe.title}}</div>
          <div class="flex flex-col my-4">
            <span class="text-lg underline">Ingredients:</span>
            <div class="flex flex-col" v-for="(list, section) in recipe.ingredients" :key="section">
              <span class="text-md font-bold">{{recipe.sections[section]}}</span>
              <div class="flex flex-col flex-nowrap">
                <span class="text-sm" v-for="(i, index) in list" :key="index">{{ i }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col my-4">
            <span class="text-lg underline">Directions:</span>
            <div class="flex flex-col" v-for="(list, section) in recipe.directions" :key="section">
              <div class="flex flex-col flex-nowrap">
                <span class="text-sm" v-for="(d, index) in list" :key="index" :class="this.directionStyle(index)" @click="setActiveDirection(index)">
                  {{index + 1}}. {{ d }}
                </span>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
export default {
  name: "RecipeCard",
  props: {
    recipe: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      activeDirection: 0
    }
  },
  methods: {
    directionStyle: function(index) {
      if (index === this.activeDirection) {
        return 'direction direction-active';
      } else {
        return 'direction';
      }
    },
    setActiveDirection: function(index) {
      this.activeDirection = index;
    },
  }
}
</script>

<style scoped>
@layer components {
  .card {
    @apply bg-gray-100 rounded-sm p-4 flex flex-col shadow-lg w-full flex-none h-full overflow-y-auto
  }

 .direction {
    @apply text-sm
  }

  .direction-active {
    @apply font-bold
  }
}
</style>