import ky from 'ky';
import { createStore } from 'vuex';

export default createStore({
  state: {
    viewableRecipes: [],
    recipes: [
      {
        title: 'Melon Pan',
        sections: ['Main Dough', 'Biscuit Dough', 'Topping'],
        ingredients: [
          [
            '1 3/4 Bread Flour',
            '3 1/2 Tbsp Cake Flour',
            '1 tsp Salt',
            '3 Tbsp Sugar',
            '1 1/3 tsp Instant Yeast',
            '1 Lg Egg',
            '3 Tbsp Whole Milk',
            '3 Tbsp Water',
            '2 1/2 Tbsp Unsalted Butter',
          ],
          [
              '4 Tbsp Unsalted Butter',
              '1/2 Cup Sugar',
              '1 Large Egg',
              '1 3/4 Cup Cake Flour',
              '1/2 tsp Baking Powder'
          ],
          [
              '2 Tbsp Sugar'
          ]
        ],
        directions: [
            [
                'In a large bowl, combine bread flour, cake flour, sugar, and salt.',
                'Add 4g instant yeast, and 1 large beaten egg to the bowl. Mix until a loose sticky ball forms.',
                'Flour surface and knead dough ball for about 5 minutes.',
                'After cube 35g of unsalted butter, and continue kneading the dough.',
                'The butter will slowly incorporate into the dough, continue folding the dough onto itself for about 10 minutes.',
                'When the dough is smooth, and the dough does not tear, shape into a ball pulling all sides down to the bottom and place in a bowl.',
                'Cover the bowl, and let dough proof at 100F, until doubled in size.',
                'In another bowl, combine buscuit dough ingredients.',
                'Start by blending sugar and butter together.',
                'Slowly add in beaten egg.',
                'Sift cake flour and baking powder into the mixture.',
                'Collect dough into a ball, and divide into 10 pieces, let rest in the fridge for 10 minutes.',
                'Now that main dough has risen, deflate it. Form into a ball again by folding into thirds.',
                'Cut the dough into 10 pieces, place on a baking sheet, and cover to let rise for 15 minutes at room temperature.',
                'Take out the biscuit dough, and roll out each piece until its about 4 inches in diameter.',
                'Deflate the main dough balls again, and reform into balls.',
                'Place biscuit dough disc in hand, then put main dough ball on top, cover ball with biscuit dough.',
                'Cover the biscuit dough side of the combined ball with sugar, and gently score a crisscross patter on top.',
                'Place back on baking sheet, cover and let rest for 50 minutes.',
                'Preheat over to 350F, when ready bake bread balls for 13-15 minutes.'
            ]
        ]
      },
      {
        title: 'Pizza Dough',
        sections: ['Dough'],
        ingredients: [
          [
            '100% Flour',
            '64% Water',
            '5% Olive Oil',
            '2% Sugar',
            '1.5% Salt',
            '1.5% Instant Yeast'
          ]
        ],
        directions: [
            [
                'Mix all ingredients in a food processor for 1 minute.',
                'Let it chill in the fridge for 72 hours.',
                'Portion out dough for each pizza, and let rise at room temperature for 2 hours.',
                'Form into pies then continue making your pizza.'
            ]
        ]
      },
        {
            title: 'French Baguette',
            sections: ['Dough'],
            ingredients: [
                [
                    '500g of all purpose flour',
                    '360g of warm water',
                    '1 Tbsp of honey',
                    '2 tsp of salt',
                    '1 tsp of instant yeast'
                ]
            ],
            directions: [
                [
                    'Mix all ingredients together and let rest for 15 minutes.',
                    'Every 30 minutes stretch and fold dough and return it to the bowl you used.',
                    'After 1.5 to 2 hours divide dough into 3 parts, and shape into rectangles, let rest for an hour.',
                    'Preheat oven to 475-500F, put a baking stone in the top section of the oven, and a pan of water in the bottom section.',
                    'Stretch and roll the dough into cylinders and seal the seams. Make each baguette about 1-1.5ft.',
                    'Score each loaf, and place in oven to cook for 15 minutes, if loaves are not dark brown, continue baking for 5 minutes at a time until ready.'
                ]
            ]
        },
        {
            title: 'Chicken Soup',
            sections: ['Soup'],
            ingredients: [
                [
                    '8 cups water',
                    '2 chicken bouillon cubes',
                    'half a large yellow or white onion diced',
                    '4 celery stalks roughly chopped',
                    '3 large carrots roughly chopped',
                    '1-2 cups swiss chard chopped',
                    '1 medium parsnip finely diced',
                    '1 lb skinless/boneless chicken thighs chunked',
                    '1 cup egg noodles',
                    '1 bay leaf',
                    '2-4 tsp salt',
                    '2 tsp oregano',
                    '1 tsp thyme',
                    '1 tsp rosemary',
                    '0.5 tsp black pepper'
                ]
            ],
            directions: [
                [
                    'Prepare ingredients, and boil water with bouillon.',
                    'Cook chicken thighs over medium heat and when almost ready pour into stock pot.',
                    'Throw in all veggies and spices and bring back to a boil.',
                    'After returning to a boil, bring down the temp to a simmer and cover for 30-40 minutes.',
                    'Add noodles and let cook for 10 minutes.',
                    'Immediately remove from heat, and serve.'
                ]
            ]
        },
        {
            title: 'Dinner Rolls',
            sections: ['Dough'],
            ingredients: [
                [
                  '240ml whole milk, warmed to about 110F',
                  '2 1/4 tsp instant yeast',
                  '2 Tbsp granulated sugar',
                  '1 large egg',
                  '60g unsalted butter, softened to room temperature and cut into 4 pieces',
                  '1 tsp salt',
                  '375g all-purpose flour'
                ]
            ],
            directions: [
                [
                    'Prepare the dough: Whisk the warm milk, yeast, and 1 Tbsp sugar together. Cover and allow to sit for 5 minutes.',
                    'Add the remaining sugar, egg, butter, salt, and 1 cup flour. Mix/Beat until it comes together, add the remaining flour. Beat until the dough comes together and pulls away from the sides of the bowl.',
                    'Knead the dough for about 3 minutes.',
                    'Lightly grease a large bowl with oil or nonstick spray. Place the dough in the bowl, turning it to coat all sides in the oil. Cover the bowl. Let rise in a warm area for 1-2 hours, it needs to double in size.',
                    'Grease a 9 inch baking pan.',
                    'When the dough is ready, punch it down to release the air. Divide the dough into 14-16 equal pieces. Shape each piece into a smooth ball and place on baking pan.',
                    'Cover rolls. Allow to rise until puffy, about 1 hour.',
                    'Adjust oven rack to a lower position and preheat oven to 350F.',
                    'Bake for 20-25 minutes or until golden brown on top.',
                ]
            ]
        },
        {
            title: 'Texas Chili',
            sections: ['Chili', 'Toppings'],
            ingredients: [
                [
                    '3 medium poblano peppers',
                    '1 medium serrano pepper',
                    '1 medium red onion',
                    '4 cloves of garlic',
                    '1 red bell pepper',
                    '1 lb flank steak',
                    '1 lb chorizo sausage',
                    '1 lb ground beef',
                    '16 oz crushed tomatoes',
                    '1 dried ancho pepper chopped',
                    '1 Tbsp chili powder',
                    '1/2 tsp cumin powder',
                    '1/4 tsp cayenne pepper powder',
                    '1/4 tsp oregano',
                    'salt and pepper to task',
                    '1/16 cup half & half',
                    '1/16 cup brown sugar'
                ],
                [
                    'Cheddar cheese grated',
                    'Sour cream',
                    'Tortilla chips'
                ]
            ],
            directions: [
                [
                    'Dice red bell pepper, onion, and garlic and. Brown the three in a pot with butter. Season with salt and pepper.',
                    'In another pan, cook ground beef, and add it to the chili pot.',
                    'Add in crushed tomatos to the chili, and mix it all together.',
                    'Add spices, serrano and ancho pepper, and stir together.',
                    'Cook chorizo sausage, chop it up, and add it to the chili pot.',
                    'Rub oil on the poblano peppers, and either bake or grill peppers until blackened. Chop them up and add to chili.',
                    'Cook steak to medium rare temp, chop into cubes, and add it to the chili.',
                    'Reduce chili to a simmer, and let it continue cooking for 30 minutes.',
                    'Lower temp, and add brown sugar, and half & half. Stir together, and cook for another 10 minutes.',
                    'Chili at this point can be cooked on low for a few hours or served to eat.',
                    'Top with desired amount of cheese, chips, and sour cream.'
                ]
            ]
        }
    ]
  },
  getters: {
    recipes (state) {
      return state.viewableRecipes;
    }
  },
  mutations: {
    setRecipes (state, recipes) {
      state.recipes = recipes;
    },
    setSearchQuery(state, text) {
      state.searchQuery = text;
    },
    setFilteredRecipes(state, recipes) {
        state.viewableRecipes = recipes;
    }
  },
  actions: {
        async filterRecipes (context, {text}) {
            let filtered = context.state.recipes.filter(recipe => {
                if (text == null || text === "") { return recipe }
                if (recipe.title.toLowerCase().includes(text.toLowerCase())) {
                    return recipe
                }
            })
            context.commit('setFilteredRecipes', filtered)
        },
        async getRecipes (context, {filter}) {
            const json = await ky.get('/api/recipes', {
                searchParams: {
                    filter: filter
                }
            }).json()
            context.commit('setRecipes', json);
        },
        async createRecipe (context, {title, sections, ingredients, directions}) {
            const json = await ky.post("/api/recipes", {
                json: {
                    title: title,
                    sections: sections,
                    ingredients: ingredients,
                    directions: directions
                }
            }).json()

            console.log(json);
        }
  },
  modules: {
  }
})
