<template>
  <new-recipe-dialog v-if="createMode" @closed="createMode = false"/>
  <div class="m-0 bg-gray-200 w-screen h-screen flex flex-col justify-center items-center font-sans">
    <div class="w-11/12 max-w-screen-md flex flex-col">
      <input v-model="search" @change="filterRecipes" placeholder="Find something to make.." type="text" class="p-1 w-full border-2 border-gray-200 rounded text-sm"/>
    </div>
    <div ref="recipeCards" class="recipes snapper" dir="ltr">
      <div v-for="(recipe, index) in $store.getters['recipes']" :key="index" class="card-bg snap-child">
        <RecipeCard :recipe="recipe"/>
      </div>
    </div>
  </div>
  <div class="hint animate-hint" ref="scrollHint">Scroll Left/Right</div>
</template>

<script>
import NewRecipeDialog from "./NewRecipeDialog";
import RecipeCard from "./RecipeCard";
export default {
  name: 'App',
  components: {RecipeCard, NewRecipeDialog},
  mounted: function() {
    this.$store.dispatch('filterRecipes', {});
  },
  data: function() {
    return {
      createMode: false,
      search: '',
      swipping: false,
      currentScroll: 0,
      swipeOrig: 0,
      swipeCurrent: 0,
      swipeVelocity: 0,
    }
  },
  methods: {
    filterRecipes: function() {
      this.$store.dispatch('filterRecipes', {
        text: this.search
      })
      this.$refs.recipeCards.scroll(0, 0);
    }
  }
}
</script>

<style>
@layer components {
  .card-bg {
    @apply bg-gray-200 rounded-sm px-2 py-2 flex flex-col w-full flex-none mx-8 h-full
  }

  .recipes {
    @apply w-screen max-w-screen-md h-4/5 overflow-auto flex flex-row flex-none flex-nowrap
  }

  .hint {
    @apply absolute select-none top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 p-4 rounded-md bg-black bg-opacity-80 text-gray-300 flex justify-center items-center text-sm
  }
}

.snapper {
  scroll-snap-type: x mandatory;
}

.snap-child {
  scroll-snap-align: center;
}

::-webkit-scrollbar {
  height: 0;
}

.animate-hint {
  animation-name: hide;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0, 0);
  }
}
</style>
