<template>
  <div class="dialog">
    <div class="flex flex-row p-4 w-full justify-between items-center">
      <span class="text-2xl">New Recipe</span>
      <button class="w-10 h-10 text-2xl font-bold bg-gray-300 text-black rounded-full" @click="closeDialog">&times;</button>
    </div>
    <span class="field-title">Title</span>
    <input class="field" type="text" placeholder="Name of recipe here.." v-model="title"/>

    <div class="flex flex-col mb-4" v-for="(s, sectionIndex) in sections" :key="sectionIndex">
      <span class="text-sm font-bold mt-2">Section({{ sectionIndex + 1 }})</span>
      <input class="field" type="text" placeholder="Ingredient section name.." v-model="sections[sectionIndex]"/>

      <span class="field-title">Ingredients</span>
      <input class="field" type="text" placeholder="1cup awesome here.." v-model="ingredients[sectionIndex][ingredientIndex]" v-for="(i, ingredientIndex) in ingredients[sectionIndex]" :key="ingredientIndex"/>
      <button class="btn" @click="addIngredient(sectionIndex)">Add Ingredient</button>

      <span class="field-title">Directions</span>
      <input class="field" type="text" placeholder="The next step here.." v-model="directions[sectionIndex][directionIndex]" v-for="(d, directionIndex) in directions[sectionIndex]" :key="directionIndex"/>
      <button class="btn" @click="addDirection(sectionIndex)">Add Direction</button>
      <hr class="h-0.5 bg-gray-800 my-1"/>
    </div>
    <button class="btn" @click="addSection">New Ingredient Section</button>

    <button class="create-btn" @click="createRecipe">Create</button>
  </div>
</template>

<script>
export default {
  name: "NewRecipeDialog",
  data: function() {
    return {
      title: '',
      sections: [],
      ingredients: [],
      directions: []
    }
  },
  methods: {
    addIngredient: function(sectionIndex) {
      this.ingredients[sectionIndex].push('')
    },
    addDirection: function(sectionIndex) {
      this.directions[sectionIndex].push('');
    },
    addSection: function() {
      this.sections.push('');
      this.ingredients.push([]);
      this.directions.push([]);
    },
    clear: function() {
      this.title = '';
      this.sections = [];
      this.ingredients = [];
      this.directions = [];
    },
    closeDialog: function() {
      this.clear();
      this.$emit('closed');
    },
    createRecipe: function() {
      this.$store.dispatch('createRecipe', {
        title: this.title,
        sections: this.sections,
        ingredients: this.ingredients,
        directions: this.directions
      })
      this.closeDialog();
    }
  }
}
</script>

<style scoped>
@layer components {
  .dialog {
    @apply absolute bg-gray-200 text-sm text-black w-screen h-screen flex flex-col font-sans p-5
  }

  .btn {
    @apply w-full text-sm bg-gray-300 my-1 p-1 rounded shadow-sm font-bold
  }

  .create-btn {
    @apply w-full text-sm my-2 p-3 rounded shadow-sm font-bold h-14 bg-blue-600 text-white
  }

  .field-title {
    @apply mt-4 font-bold
  }

  .field {
    @apply p-1 w-full border-2 border-gray-200 rounded text-sm
  }
}
</style>